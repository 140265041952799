import logo from './logo.svg';
import './App.css';
import axios from 'axios'
import {useState, useEffect} from 'react'

function App() {

  const [count, setCount] = useState('');

  useEffect(()=> {
    axios.get(`http://localhost:8000/api/v1/product/all`)
    .then(result => {
        const ResponseAPI = result.data.values
        console.log(ResponseAPI)
        setCount(ResponseAPI, 'ada ga')
    })
    .catch(err => {
        console.log('error: ', err)
    })
  },[])

  return (
    <div className="App">
      {Object.keys(count).map(function(key, index) {
        return (
          <view>
            <p>{count[key].owner}</p>
            <p>{count[key].product_name}</p>
            <p>{count[key].price}</p>
          </view>
        
        )
  
})}
    </div>
  );
}

export default App;
